import { useRef, useState } from "react";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";

type Props = {
  closeModal: Function;
  show: boolean;
  link?: string;
  auth: AuthState;
};

const InviteModal = ({ closeModal, show, auth }: Props) => {
  const ref = useRef(null);
  const [link, setLink] = useState("");

  const getInviteLink = () => {
    axiosClient("/api/auth/invite", {
      method: "get",
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
    }).then(
      (result) => {
        setLink(
          window.location.protocol +
            "//" +
            window.location.hostname +
            "/register/" +
            result.data
        );
      },
      (err) => {
        if (!(err.response.status === 403)) {
          console.log(err);
        } else {
          window.location.reload();
        }
      }
    );
  };

  return (
    <div
      ref={ref}
      onClick={(e) => {
        if (e?.target === ref.current) closeModal();
      }}
      className="bg-slate-400 bg-opacity-70 z-10 inset-0 absolute w-screen h-screen flex flex-col justify-around items-center"
      style={{ visibility: show ? "visible" : "hidden" }}
    >
      <div className="bg-white p-8 z-20 shadow-lg rounded w-3/4 md:w-1/3">
        <span
          className={
            "block mb-2 border w-full text-center p-2" +
            (!link ? " text-slate-300" : "")
          }
        >
          {link ? link : "No link"}
        </span>
        <button
          onClick={(e) => getInviteLink()}
          className="block mx-auto bg-blue-500 text-white p-2 px-8 rounded"
        >
          Generate Invite
        </button>
        <button
          onClick={(e) => closeModal()}
          className="block mx-auto mt-8 bg-blue-500 text-white p-2 px-8 rounded"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default InviteModal;
