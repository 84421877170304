import { useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AuthState } from "../types/AuthState";
import Loading from "./Loading";

type Props = { auth: AuthState; refresh: Function };

const PresistLogin = ({ auth, refresh }: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    try {
      if (!auth.user) {
        refresh();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [auth, refresh]);

  return <>{isLoading ? <Loading /> : <Outlet />}</>;
};

export default PresistLogin;
