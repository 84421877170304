import { useRef } from "react";
import { FaCheck, FaTimes, FaTrashAlt } from "react-icons/fa";
import Tooltip from "../../components/Tooltip";

type Props = {
  closeModal: Function;
  show: Boolean;
  order: any;
  confirmOrder: Function;
  deleteOrder: Function;
};

const DetailsModal = ({
  closeModal,
  show,
  order,
  confirmOrder,
  deleteOrder,
}: Props) => {
  const ref = useRef(null);

  return (
    <div
      ref={ref}
      onClick={(e) => {
        if (e?.target === ref.current) closeModal();
      }}
      className=" bg-slate-400 bg-opacity-70 z-10 inset-0 absolute w-screen h-screen flex flex-col justify-around items-center"
      style={{ visibility: show ? "visible" : "hidden" }}
    >
      <div className="w-5/6 md:w-1/2 bg-white rounded shadow-lg p-4 relative">
        <div className="absolute right-4">
          <FaTimes
            className="text-gray-400 cursor-pointer"
            onClick={(e) => closeModal()}
          />
        </div>
        <h1 className="text-center text-xl">Order: {order.reference}</h1>
        <table className="text-center border shadow-lg table-fixed w-full mt-2 ">
          <thead className="bg-slate-50 text shadow">
            <tr>
              <th>Item</th>
              <th>Price</th>
              <th>Amount</th>
              <th>Total cost</th>
            </tr>
          </thead>
          <tbody>
            {!order.rows || order.rows === 0 ? (
              <tr>
                <td className="col-span-4">No Rows</td>
              </tr>
            ) : (
              order.rows.map((row: any) => {
                const rowData = JSON.parse(row.rowInfo);

                return (
                  <tr className="even:bg-slate-100" key={rowData.id}>
                    <td>{rowData.item}</td>
                    <td>{rowData.price} Kr</td>
                    <td>{rowData.amount}</td>
                    <td>{rowData.amount * rowData.price} Kr</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className="flex justify-between">
          <div className="flex gap-2">
            {order.confirmed ? (
              <> </>
            ) : (
              <Tooltip tooltipText="Confirm order">
                <button onClick={e => (confirmOrder(order.reference))} className="mt-4">
                  <FaCheck className="text-green-600" />
                </button>
              </Tooltip>
            )}
            <Tooltip tooltipText="Delete order">
              <button onClick={e => (deleteOrder(order.reference))} className="mt-4">
                <FaTrashAlt className="text-red-600" />
              </button>
            </Tooltip>
          </div>
          <div className="flex-col flex justify-center">
            <h1>Order total: {order.total} Kr</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;
