import React from "react";
import { AuthState } from "../../types/AuthState";
import { Theme } from "../../types/Theme";
import ItemTable from "./ItemTable";

type Props = {
  handleNewItem: Function;
  auth: AuthState;
  data: any;
  setData: Function,
  handleDelete: Function;
  theme?: Theme;
};

const ItemTab = ({ handleNewItem, handleDelete, auth, data, theme, setData }: Props) => {
  return (
    <div className="lg:mx-auto w-full pt-4 pb-8">
      <button
        onClick={(e) => handleNewItem(e)}
        className="p-1  rounded"
        style={{
          backgroundColor:
            theme && theme.navbarColor !== ""
              ? "#" + theme?.navbarColor
              : "#3b82f6",
          color:
            theme && theme.navbarTextColor !== ""
              ? "#" + theme?.navbarTextColor
              : "#fff",
        }}
      >
        New Item
      </button>

      <ItemTable auth={auth} data={data} setData={setData} handleDelete={handleDelete} />
    </div>
  );
};

export default ItemTab;
