import { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";

type Props = { item: any; handleDelete: Function; updateItem: Function, auth: AuthState };

const ItemTableRow = ({ item, auth, handleDelete, updateItem }: Props) => {
  const [editMode, setEditMode] = useState(false);

  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState<number>(item.price);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    axiosClient("/api/item/" + item.id, {
      method: "put",
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        name: name,
        price: price,
      },
    }).then(
      (res) => {
        updateItem({...item, name: name, price: price})
      },
      (err) => {
        console.error(err);
        window.location.reload();
      }
    );


    
    setEditMode(false);
  };

  return editMode ? (
    <tr className="even:bg-slate-100">
      <td className="p-2">
        <input
          className="text-center border w-full"
          type="text"
          name="name"
          id="name"
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSave();
          }}
        />
      </td>
      <td className="p-2">
        <input
          className="text-center border w-full"
          type="number"
          name="price"
          id="price"
          defaultValue={price}
          onChange={(e) => setPrice(+e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSave();
          }}
        />
      </td>
      <td>
        <button
          onClick={handleSave}
          className="bg-blue-500 p-1 text-white rounded"
        >
          Save
        </button>
      </td>
      <td
        onClick={(event) => {
          handleDelete();
        }}
        className=""
      >
        <div className="mx-auto flex justify-around cursor-pointer text-red-700">
          <FaTrash />
        </div>
      </td>
    </tr>
  ) : (
    <tr className="even:bg-slate-100" key={item.id}>
      <td className="p-2">{name}</td>
      <td className="p-2">{price}</td>
      <td
        onClick={(event) => {
          handleEdit();
        }}
      >
        <div className="mx-auto flex justify-around cursor-pointer ">
          <FaEdit />
        </div>
      </td>
      <td
        onClick={(event) => {
          handleDelete(item.id);
        }}
        className=""
      >
        <div className="mx-auto flex justify-around cursor-pointer text-red-700">
          <FaTrash />
        </div>
      </td>
    </tr>
  );
};

export default ItemTableRow;
