import React from "react";

type Props = { children?: React.ReactNode; className?: string };

const ItemList = ({ children, className }: Props) => {
  return (
    <div
      className={
        "container justify-around grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mx-auto " +
        className
      }
    >
      {children}
    </div>
  );
};

export default ItemList;
