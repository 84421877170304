import React, { useState } from "react";
import DetailsModal from "./OrderDetailsModal";
import OrdersTable from "./OrdersTable";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";
import moment from "moment";

type Props = { orders: any; setOrders: Function, auth: AuthState };

const OrdersTab = ({ orders, setOrders, auth, }: Props) => {
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState<any>({});

  const deleteOrder = async(ref: string) => {
    if(!window.confirm('Are you sure you want to delete this order?')) return;

    await axiosClient(`/api/order/${ref}`, {
      method: "delete",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
    }).then(res => {
      setOrders(orders.filter((x: any) => x.reference !== ref))
      setShow(false);
    }, err => {
      if(err !== null) return;
    })
  };
  const confirmOrder = async (ref: string) => {
    if(!window.confirm('Are you sure you want to confirm this order?')) return;

    await axiosClient(`/api/order/${ref}`, {
      method: "patch",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
    }).then(res => {
      let newOrders = orders.map((x:any) => {
        if(x.reference === ref){
          x.confirmed = true;
        }
        return x;
      })
      setOrders(newOrders)
      setShow(false);
    }, err => {
      if(err !== null) return;
    })
  };
  let isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;




  return (
    <div className="lg:mx-auto w-full pt-4 pb-8">
      <OrdersTable
        orders={orders}
        openModal={(order: any) => {
          if (isMobile)
            document
              .getElementById("root")
              ?.setAttribute("style", "position: fixed; overflow: hidden;");
          setOrder(order);
          setShow(true);
        }}
      />
      <DetailsModal
        deleteOrder={deleteOrder}
        confirmOrder={confirmOrder}
        show={show}
        order={order}
        closeModal={() => {
          document.getElementById("root")?.removeAttribute("style");
          setShow(false);
        }}
      />
    </div>
  );
};

export default OrdersTab;
