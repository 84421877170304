import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";
import ShopTable from "./ShopTable";
import UserTable from "./UserTable";

type Props = { auth: AuthState };

const AdminDashboard = ({ auth }: Props) => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!auth.user?.isAdmin) {
      navigate("/");
    }

    async function getData(resend = false) {
      return await axiosClient
        .get("/api/admin/dashboard", {
          method: "get",
          headers: { Authorization: "Bearer " + auth.user?.accessToken },
        })
        .then(
          (res) => {
            setData(res.data);
          },
          async (err) => {
            if (!(err.response.status === 403)) {
              console.log(err);
            } else {
              window.location.reload();
            }
          }
        );
    }
    try {
      getData();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }

    return () => {};
  }, [auth, navigate]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Navbar title="Admin Dashboard" />
      <div className="container mx-auto">
        <div className="p-4">
          <UserTable users={data.users} />
          <ShopTable shops={data.shops} />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
