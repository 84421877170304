import React, { useEffect, useState } from "react";

type Props = { users: any };

const UserTable = ({ users }: Props) => {
  let keys;
  if (users !== undefined && users.length !== 0) keys = Object.keys(users[0]);

  const [select, setSelect] = useState("All");
  const [filteredUsers, setFilteredUsers] = useState<any>(users);

  useEffect(() => {
    if (select === "All") setFilteredUsers(users);
    if (select === "Users")
      setFilteredUsers(users.filter((x: any) => !x.isAdmin));
    if (select === "Admins")
      setFilteredUsers(users.filter((x: any) => x.isAdmin));

    return () => {
      setFilteredUsers({});
    };
  }, [select, users]);

  return !users || users.lengt === 0 ? (
    <h1 className="text-center mb-8">No Users</h1>
  ) : (
    <div className="mt-8">
      <button
        className="mr-2"
        style={{ textDecoration: select === "All" ? "underline" : "" }}
        onClick={(e) => {
          setSelect("All");
        }}
      >
        All
      </button>
      <button
        style={{ textDecoration: select === "Users" ? "underline" : "" }}
        onClick={(e) => {
          setSelect("Users");
        }}
        className="mr-2"
      >
        Users
      </button>
      <button
        style={{ textDecoration: select === "Admins" ? "underline" : "" }}
        onClick={(e) => {
          setSelect("Admins");
        }}
        className="mr-2"
      >
        Admins
      </button>
      <table className="text-center border shadow-lg table-fixed w-full mt-2">
        <thead className="bg-slate-50 text shadow">
          <tr>
            {keys?.map((key) => {
              return (
                <td key={key}>
                  {key.charAt(0).toUpperCase() + key.substring(1)}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className="">
          {!filteredUsers || filteredUsers.length === 0 ? (
            <tr>
              <td colSpan={4}>No Users</td>
            </tr>
          ) : (
            filteredUsers.map((user: any) => {
              return (
                <tr className="even:bg-slate-100" key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.isAdmin ? "Admin" : "User"}</td>
                  <td>{user.shops.length}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
