import { SyntheticEvent } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";

type Props = { auth: AuthState };

const NewItem = ({ auth }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const from = (location as any).state?.from.pathname;

  if (!from) {
    navigate(auth?.user ? "/profile" : "/");
  }

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const name = ((e.target as HTMLFormElement)[0] as HTMLInputElement)?.value;
    const price = ((e.target as HTMLFormElement)[1] as HTMLInputElement)?.value;

    axiosClient("/api/item", {
      method: "post",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        name: name,
        price: price,
        shop: from.split("/")[1],
        username: auth.user?.username,
      },
    }).then(
      () => {
        navigate(from);
      },
      (err) => {
        if (!(err.response.status === 403)) {
          alert(err.response.data.message);
        } else {
          window.location.reload();
        }
      }
    );
  };

  return (
    <div>
      <Navbar
        titleElement={
          <FaChevronLeft
            className="cursor-pointer"
            onClick={() => navigate(from)}
          />
        }
      ></Navbar>
      <div className="container mx-auto mt-8">
        <h1 className="text-center font-normal mb-4">New Item</h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 mx-auto">
          <input
            className="p-2 rounded border md:w-96 mx-auto"
            type="text"
            name="name"
            id="name"
            autoComplete="off"
            placeholder="Name"
          />

          <input
            className="p-2 rounded border md:w-96 mx-auto"
            type="number"
            name="price"
            id="price"
            placeholder="Price"
          />
          <input
            className="p-2 bg-blue-500 text-white md:w-96 mx-auto cursor-pointer"
            type="submit"
            value="Create Item"
          />
        </form>
      </div>
    </div>
  );
};

export default NewItem;
