import React from "react";

type Props = {};

const NoShop = (props: Props) => {
  return (
    <div className="container mx-auto">
      <div className="flex-col flex justify-around align-middle h-screen">
        <h1 className="text-center">No shop found</h1>
      </div>
    </div>
  );
};

export default NoShop;
