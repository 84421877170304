import React, { useEffect, useState } from "react";
import { Theme } from "../../types/Theme";
import axiosClient from "../../utils/axiosClient";
import generateSwishURI from "../../utils/generateSwishUri";

type Props = {
  data: any;
  clearCost: Function;
  theme: Theme;
  order: Order;
};

type Order = { total: number; rows: Array<{ item: any; amount: number }> };

const Checkout = ({ data, clearCost, theme, order }: Props) => {

  const openSwish = async () => {
    const ref = await createOrder();
    console.log(ref);
    const link = generateSwishURI(
      data.number ?? "",
      order.total,
      data.prefrences.defaultMessage ?? "",
      data.name,
      ref
    );
    console.log(JSON.stringify(order));
    window.location.replace(link);
  };

  const createOrder = async () => {
    return await axiosClient(`/api/order/`, {
      method: "post",
      data: { ...order, shopName: data.name },
    }).then(
      (result) => {
        return result?.data?.reference;
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <div
      onClick={() => openSwish()}
      className={
        "cursor-pointer p-6 w-2/3 md:w-1/3 border shadow-lg font-bold mx-auto rounded-lg inset-x-0 fixed bottom-10 -left-0 bg-data text-center ease-in-out " +
        (order.total === 0 ? " hidden " : " ")
      }
      style={{
        backgroundColor:
          theme && theme.navbarColor !== ""
            ? "#" + theme?.navbarColor
            : "#3b82f6",
        color:
          theme && theme.navbarTextColor !== ""
            ? "#" + theme?.navbarTextColor
            : "#fff",
      }}
    >
      Checkout: {order.total} SEK
    </div>
  );
};

export default Checkout;
