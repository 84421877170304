import React, { ReactNode, useState } from "react";

type Props = {
  tooltipText?: string;
  children?: ReactNode;
  className?: string;
  childClassName?: string;
  tooltipClassName?: string;
  left?: boolean;
};

const Tooltip = ({
  tooltipText,
  children,
  className,
  childClassName,
  tooltipClassName,
  left = false,
}: Props) => {
  const [show, setShow] = useState(false);

  const showTooltip = () => {
    setShow(true);
  };
  const hideTooltip = () => {
    setShow(false);
  };

  return (
    <div className={className}>
      <div
        className={childClassName}
        onMouseOver={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      <span
        style={{ visibility: show ? "visible" : "hidden" }}
        className={
          "absolute bg-black text-white rounded text-xs p-1 w-fit " +
          (left ? "-translate-x-full " : "") +
          tooltipClassName
        }
      >
        {tooltipText}
      </span>
    </div>
  );
};

export default Tooltip;
