import React, { useState } from "react";
import { AuthState } from "../../types/AuthState";
import { Theme } from "../../types/Theme";
import axiosClient from "../../utils/axiosClient";

type Props = {
  auth: AuthState;
  data: any;
  settingTitle: string;
  settingName: string;
  settingValue: string;
  setSettingValue: Function;
  theme: Theme;
};

const DataSetting = ({
  auth,
  data,
  settingTitle,
  settingName,
  settingValue,
  setSettingValue,
  theme,
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const handleSave = async () => {
    axiosClient("/api/shop/" + data.name, {
      method: "put",
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        [settingName]: editedValue,
      },
    }).then(
      (res) => {
        setSettingValue(editedValue);
      },
      (err) => {
        console.error(err);
        window.location.reload();
      }
    );

    setEditMode(false);
  };

  return (
    <div className="grid grid-cols-4 md:grid-cols-5 justify-between align-middle py-4 border-b items-center">
      <label htmlFor={settingName} className="font-medium">
        {settingTitle}
      </label>
      {editMode ? (
        <>
          <input
            autoFocus
            className="ml-2 p-2 col-span-2 md:col-span-3"
            type="text"
            name={settingName}
            id={settingName}
            defaultValue={settingValue}
            onChange={(e) => setEditedValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSave();
            }}
          />
          <button
            onClick={(e) => handleSave()}
            className="ml-auto border p-2 rounded w-fit "
            style={{
              borderColor:
                theme && theme.navbarColor !== ""
                  ? "#" + theme?.navbarColor
                  : "#3b82f6",
              color:
                theme && theme.navbarColor !== ""
                  ? "#" + theme?.navbarColor
                  : "#3b82f6",
            }}
          >
            Save
          </button>
        </>
      ) : (
        <>
          <h1 className="ml-2 p-2 col-span-2 md:col-span-3">{settingValue}</h1>
          <button
            onClick={(e) => {
              setEditedValue(settingValue);
              setEditMode(true);
            }}
            className="ml-auto border p-2 rounded w-fit "
            style={{
              borderColor:
                theme && theme.navbarColor !== ""
                  ? "#" + theme?.navbarColor
                  : "#3b82f6",
              color:
                theme && theme.navbarColor !== ""
                  ? "#" + theme?.navbarColor
                  : "#3b82f6",
            }}
          >
            Change
          </button>
        </>
      )}
    </div>
  );
};

export default DataSetting;
