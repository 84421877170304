import React from "react";
import { FaCog, FaCrown, FaStore } from "react-icons/fa";
import { Link } from "react-router-dom";
import Tooltip from "../../components/Tooltip";
import { AuthState } from "../../types/AuthState";

type Props = { data: any; auth: AuthState };

const Table = ({ data, auth }: Props) => {
  return (
    <table className="w-full text-center m-2 mx-auto shadow-lg border">
      <thead className="bg-slate-50">
        <tr>
          <th>Shop</th>
          <th className="hidden md:table-cell">Items</th>
          <th className="hidden md:table-cell">Number</th>
          <th>Shop</th>
          <th>Dashboard</th>
        </tr>
      </thead>
      <tbody className="">
        {!data.shops || data.shops.length === 0 ? (
          <tr>
            <td colSpan={5}>No shops</td>
          </tr>
        ) : (
          data.shops?.map((x: any) => {
            return (
              <tr className="even:bg-slate-100" key={x.id}>
                <td className="p-4">
                  {x.owner.username === auth.user?.username ? (
                    <FaCrown className="text-orange-400 inline-block absolute translate-y-1 -translate-x-5" />
                  ) : (
                    <></>
                  )}
                  {x.title}
                </td>
                <td className="p-4 hidden md:table-cell">{x.items.length}</td>
                <td className="p-4 hidden md:table-cell">{x.number}</td>
                <td className="p-4 hidden md:table-cell hover:underline">
                  <Link to={"/" + x.name}>Go to Shop</Link>
                </td>
                <td className="p-4 hidden md:table-cell hover:underline">
                  <Link to={"/" + x.name + "/dashboard"}>Go to Dashboard</Link>
                </td>
                <td className="p-4 table-cell md:hidden hover:underline">
                  <Tooltip tooltipText="Go to store">
                    <Link className="flex justify-around" to={"/" + x.name}>
                      <FaStore />
                    </Link>
                  </Tooltip>
                </td>
                <td className="p-4 table-cell md:hidden hover:underline">
                  <Tooltip left={true} tooltipText="Go to dashboard">
                    <Link
                      className="flex justify-around"
                      to={"/" + x.name + "/dashboard"}
                    >
                      <FaCog />
                    </Link>
                  </Tooltip>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default Table;
