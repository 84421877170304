import React from "react";
import moment from "moment";

type Props = { orders: any };

const StatsTab = ({ orders }: Props) => {
  return (
    <div>
      {" "}
      <div className="mb-4 justify-evenly">
        <div className="border-b py-4">
          <h1 className="font-medium">Today</h1>
          <div className="flex justify-between">
            <h2>
              {
                orders.filter((order: any) => {
                  return (
                    moment(order.createdAt).isSame(Date.now(), "day") &&
                    order.confirmed
                  );
                }).length
              }{" "}
              Confirmed Orders
            </h2>
            <h2>
              {orders
                .filter((order: any) => {
                  return (
                    moment(order.createdAt).isSame(Date.now(), "day") &&
                    order.confirmed
                  );
                })
                .reduce((acc: number, { total }: any) => {
                  return acc + total;
                }, 0)}{" "}
              Kr
            </h2>
          </div>
        </div>
        <div className="border-b py-4">
          <h1 className="font-medium">Week</h1>
          <div className="flex justify-between">
            <h2>
              {
                orders.filter((order: any) => {
                  return (
                    moment(order.createdAt).isSame(Date.now(), "week") &&
                    order.confirmed
                  );
                }).length
              }{" "}
              Confirmed Orders
            </h2>
            <h2>
              {orders
                .filter((order: any) => {
                  return (
                    moment(order.createdAt).isSame(Date.now(), "week") &&
                    order.confirmed
                  );
                })
                .reduce((acc: number, { total }: any) => {
                  return acc + total;
                }, 0)}{" "}
              Kr
            </h2>
          </div>
        </div>
        <div className="border-b py-4">
          <h1 className="font-medium">Month</h1>
          <div className="flex justify-between">
            <h2>
              {
                orders.filter((order: any) => {
                  return (
                    moment(order.createdAt).isSame(Date.now(), "month") &&
                    order.confirmed
                  );
                }).length
              }{" "}
              Confirmed Orders
            </h2>
            <h2>
              {orders
                .filter((order: any) => {
                  return (
                    moment(order.createdAt).isSame(Date.now(), "month") &&
                    order.confirmed
                  );
                })
                .reduce((acc: number, { total }: any) => {
                  return acc + total;
                }, 0)}{" "}
              Kr
            </h2>
          </div>
        </div>
        <div className="border-b py-4">
          <h1 className="font-medium">Total</h1>
          <div className="flex justify-between">
            <h2>
              {
                orders.filter((order: any) => {
                  return order.confirmed;
                }).length
              }{" "}
              Confirmed Orders
            </h2>
            <h2>
              {orders
                .filter((order: any) => {
                  return order.confirmed;
                })
                .reduce((acc: number, { total }: any) => {
                  return acc + total;
                }, 0)}{" "}
              Kr
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsTab;
