import React, { SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import axiosClient from "../../utils/axiosClient";

type Props = {};

const Register = (props: Props) => {
  const { invite } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
      repassword: { value: string };
    };
    const username = target.username.value;
    const password = target.password.value;
    const repassword = target.repassword.value;

    if (repassword !== password) {
      alert("Passwords must match");
      target.password.value = "";
      target.repassword.value = "";
      return;
    }

    axiosClient("/api/auth/register", {
      method: "post",
      data: { username: username, password: password, token: invite },
    }).then(
      () => {
        navigate("/login");
      },
      (err) => {
        alert(
          err.response?.data?.message
            ? err.response.data.message
            : "Error creating user"
        );
        target.username.value = "";
        target.password.value = "";
        target.repassword.value = "";
        setIsLoading(false);
      }
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="relative">
      <div className="flex justify-around w-screen">
        <div className="h-screen flex flex-col justify-around">
          <form
            className="flex flex-col justify-around p-4 bg-white shadow-xl border rounded-lg"
            onSubmit={handleRegister}
          >
            <label htmlFor="username">Username</label>
            <input
              required
              className="p-2 border"
              type="text"
              name="username"
              id="username"
            />
            <label htmlFor="password">Password</label>
            <input
              required
              className="p-2 border"
              type="password"
              name="password"
              id="password"
            />
            <label htmlFor="password">Repeat Password</label>
            <input
              required
              className="p-2 border"
              type="password"
              name="repassword"
              id="repassword"
            />
            <input
              className="mt-2 bg-blue-500 p-2 cursor-pointer text-white rounded "
              type="submit"
              value="Sign Up"
            />
          </form>
          
        </div>
      </div>
    </div>
  );
};

export default Register;
