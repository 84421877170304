import React from "react";

type Props = { loading?: boolean };

const Loading = ({ loading = true }: Props) => {
  return (
    <div
      className="flex justify-around w-screen absolute h-screen cursor-wait opacity-60 bg-slate-600 z-10"
      style={{ visibility: loading ? "visible" : "hidden" }}
    >
      <div className="text-white text-6xl flex flex-col justify-around cursor-wait z-20">
        Loading...
      </div>
    </div>
  );
};

export default Loading;
