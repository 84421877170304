import { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PresistLogin from "./components/PresistLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminDashboard from "./pages/Admin";
import Login from "./pages/Login";
import NewItem from "./pages/NewItem";
import NewShop from "./pages/NewShop";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import Shop from "./pages/Shop";
import ShopDashboard from "./pages/ShopDashboard";
import { AuthState } from "./types/AuthState";
import { User } from "./types/User";
import axiosClient from "./utils/axiosClient";

function App() {
  const [auth, setAuth] = useState<AuthState>({
    loading: false,
    user: null,
  });

  function setLoading(value: boolean) {
    setAuth((prev) => {
      return { user: prev.user, loading: value };
    });
  }
  function setUser(user: User | null) {
    setAuth((prev) => {
      return { user: user, loading: prev.loading };
    });
  }

  async function logout() {
    setUser(null);

    await axiosClient("/api/auth/logout", {
      method: "get",
      withCredentials: true,
    });
  }

  async function refresh() {
    await axiosClient("/api/auth/refresh", {
      method: "get",
      withCredentials: true,
    }).then(
      (res) => {
        setUser(res.data.user);
      },
      (err) => {
        console.log(err);
        return null;
      }
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register/:invite" element={<Register />} />
        <Route element={<PresistLogin auth={auth} refresh={refresh} />}>
          {/*           <Route path="/" element={<Home setAuth={setAuth} auth={auth} />} />
           */}{" "}
          <Route path="/" element={<Navigate to={"/login"} replace />} />
          <Route path="/:shop" element={<Shop />} />
          <Route
            path="/login"
            element={
              <Login auth={auth} setLoading={setLoading} setUser={setUser} />
            }
          />
          <Route element={<ProtectedRoute auth={auth} setUser={setUser} />}>
            <Route
              path="/profile"
              element={
                <Profile auth={auth} refresh={refresh} logout={logout} />
              }
            />
            <Route
              path="/:shop/dashboard"
              element={<ShopDashboard auth={auth} />}
            ></Route>
            <Route path="/new/shop" element={<NewShop auth={auth} />}></Route>
            <Route path="/new/item" element={<NewItem auth={auth} />}></Route>
            <Route
              path="/admin"
              element={<AdminDashboard auth={auth} />}
            ></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
