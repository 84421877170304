import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import axiosClient from "../../utils/axiosClient";
import { FaCog } from "react-icons/fa";
import Item from "./Item";
import ItemList from "./ItemList";
import Checkout from "./Checkout";
import NoShop from "./NoShop";
import { title } from "process";

type Props = {};

type Order = { total: number; rows: Array<{ item: any; amount: number }> };

const Shop = (props: Props) => {
  const params = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [theme, setTHeme] = useState({
    navbarColor: "",
    navbarTextColor: "",
  });
  const [order, setOrder] = useState<Order>({ total: 0, rows: [] });

  const clearCost = () => {
    window.location.reload();
  };

  useEffect(() => {
    const getShopData = async () => {
      let path = "/api/shop/" + params?.shop;
      axiosClient(path, {
        method: "get",
        withCredentials: true,
      }).then(
        (res) => {
          setTHeme({
            navbarColor: res.data.prefrences.navbarColor,
            navbarTextColor: res.data.prefrences.navbarTextColor,
          });
          setData(res.data);
        },
        (err) => {
          console.log(err);
        }
      );
    };

    try {
      getShopData();
      const savedItems = JSON.parse(localStorage.getItem("items") ?? "{}");
      console.log(savedItems);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  return isLoading ? (
    <Loading loading={true} />
  ) : !data ? (
    <NoShop />
  ) : (
    <>
      <Navbar theme={theme} title={data.title}>
        <Link to={"/" + data?.name + "/dashboard"} state={{ from: location }}>
          <FaCog />
        </Link>
      </Navbar>
      <ItemList className={order.rows.length > 0 ? "mb-32" : "mb-5"}>
        {data?.items?.map((item: any) => {
          return (
            <Item
              key={item.id}
              theme={theme}
              item={item}
              order={order}
              setOrder={setOrder}
            ></Item>
          );
        })}
      </ItemList>
      <Checkout order={order} theme={theme} data={data} clearCost={clearCost} />
    </>
  );
};

export default Shop;
