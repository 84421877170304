import {
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaFastBackward,
  FaFastForward,
  FaTimes,
} from "react-icons/fa";
import moment from "moment";
import { useEffect, useState } from "react";

type Props = { orders: any; openModal: Function };

const OrdersTable = ({ orders, openModal }: Props) => {
  const [pagination, setPagination] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState<Array<Array<any>>>();

  function chunk(orders: Array<any>, pageSize: number) {
    const res = [];
    for (let i = 0; i < orders.length; i += pageSize) {
      const chunk = orders.slice(i, i + pageSize);
      res.push(chunk);
    }
    return res;
  }

  useEffect(() => {
    if (orders) {
      setPages(chunk(orders, 5));
      setPagination(chunk(orders, 5)[currentPage]);
      console.log();
    }
  }, [orders]);

  const nextPage = () => {
    if (!pages || currentPage + 1 >= pages.length) return;
    setCurrentPage((prev) => prev + 1);
    setPagination(pages[currentPage + 1]);
  };
  const lastPage = () => {
    if (!pages) return;
    setCurrentPage((prev) => pages.length - 1);
    setPagination(pages[pages.length - 1]);
  };

  const firstPage = () => {
    if (!pages) return;
    setCurrentPage((prev) => 0);
    setPagination(pages[0]);
  };
  const prevPage = () => {
    if (!pages || currentPage - 1 < 0) return;
    setCurrentPage((prev) => prev - 1);
    setPagination(pages[currentPage - 1]);
  };

  return (
    <>
      <div className="flex justify-between">
        <span>Total orders: {orders.length}</span>
        <div
          className="flex items-center"
          style={{ visibility: orders.length > 0 ? "visible" : "hidden" }}
        >
          <span className="mr-4">
            Page: {currentPage + 1}/{pages?.length}
          </span>
          <FaFastBackward
            onClick={(e) => {
              firstPage();
            }}
            className="cursor-pointer"
          />
          <FaChevronLeft
            onClick={(e) => {
              prevPage();
            }}
            className="cursor-pointer"
          />
          <FaChevronRight
            onClick={(e) => {
              nextPage();
            }}
            className="cursor-pointer"
          />
          <FaFastForward
            onClick={(e) => {
              lastPage();
            }}
            className="cursor-pointer"
          />
        </div>
      </div>
      <table className="text-center border shadow-lg table-fixed w-full mt-2">
        <thead className="bg-slate-50 text shadow">
          <tr>
            <th className="font-light">Reference</th>
            <th className="hidden sm:table-cell font-light">Total</th>
            <th className="font-light">Items</th>
            <th className="font-light">Created</th>
            <th className="font-light">Confirmed</th>
          </tr>
        </thead>
        <tbody>
          {!pagination || pagination.length === 0 ? (
            <>
              <tr className="hidden sm:table-row">
                <td className="p-2 " colSpan={5}>No Orders</td>
              </tr>
              <tr className="table-row sm:hidden">
                <td className="p-2 " colSpan={4}>No Orders</td>
              </tr>
            </>
          ) : (
            pagination.map((order: any) => {
              return (
                <tr
                  key={order.reference}
                  onClick={(e) => {
                    openModal(order);
                  }}
                  className="even:bg-slate-100"
                >
                  <td className="p-4">{order.reference}</td>
                  <td className="hidden sm:table-cell  p-4">
                    {order.total} Kr
                  </td>
                  <td className="p-4">{order.rows.length}</td>
                  <td className="p-4">{moment(order.createdAt).fromNow()}</td>
                  <td className="p-4">
                    {order.confirmed ? (
                      <FaCheck className="text-green-600 mx-auto" />
                    ) : (
                      <FaTimes className="text-red-600 mx-auto" />
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
};

export default OrdersTable;
