import React from "react";
import { Navigate } from "react-router-dom";

type Props = { message: string; to?: string };

const AlertRedirect = ({ message, to }: Props) => {
  alert(message);

  return (
    <>
      <Navigate to={to ? to : "/"} />
    </>
  );
};

export default AlertRedirect;
