import { SyntheticEvent } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";

type Props = { auth: AuthState; setLoading: Function; setUser: Function };

const Login = ({ auth, setLoading, setUser }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location as any).state?.from?.pathname;

  async function handleLogin(e: SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      username: { value: string };
      password: { value: string };
    };
    const username = target.username.value;
    const password = target.password.value;

    setLoading(true);

    await axiosClient("/api/auth/login", {
      method: "post",
      withCredentials: true,
      data: {
        username: username,
        password: password,
      },
    }).then(
      (res) => {
        setLoading(false);
        setUser(res.data.user);
        navigate(from ? from : "/profile");
      },
      (err) => {
        setLoading(false);
        console.log(err);
        alert(
          err?.response?.data.message
            ? err.response.data.message
            : err.response.statusText
        );
      }
    );
  }
  if (auth.user) {
    return <Navigate to={from ? from : "/profile"} />;
  }

  return (
    <div className="relative">
      <div className="flex justify-around w-screen">
        <div className="h-screen flex flex-col justify-around">
          <form
            className="flex flex-col justify-around p-4 bg-white shadow-xl border rounded-lg"
            onSubmit={handleLogin}
          >
            <label htmlFor="username">Username</label>
            <input
              className="p-2 border"
              type="text"
              name="username"
              id="username"
            />
            <label htmlFor="password">Password</label>
            <input
              className="p-2 border"
              type="password"
              name="password"
              id="password"
            />
            <input
              className="mt-2 bg-blue-500 p-2 cursor-pointer text-white rounded "
              type="submit"
              value="Log In"
            />
          </form>
        </div>
        <Loading loading={auth.loading} />
      </div>
    </div>
  );
};

export default Login;
