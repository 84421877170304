import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthState } from "../types/AuthState";

type Props = {
  auth: AuthState;
  redirectPath?: string;
  setUser: Function;
};

const ProtectedRoute = ({ auth, setUser, redirectPath = "/login" }: Props) => {
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
