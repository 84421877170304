import { useEffect, useState } from "react";
import { FaSignOutAlt, FaUserPlus, FaUserShield } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import Tooltip from "../../components/Tooltip";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";
import InviteModal from "./InviteModal";
import Table from "./Table";

type Props = { auth: AuthState; logout: Function; refresh: Function };

const Profile = ({ auth, logout }: Props) => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
  };

  function handleLogout() {
    navigate("/");
    logout();
  }

  function handleNewShop() {
    navigate("/new/shop", { state: { from: location } });
  }

  useEffect(() => {
    async function getUserData(resend = false) {
      return await axiosClient
        .get("/api/user/", {
          method: "get",
          headers: { Authorization: "Bearer " + auth.user?.accessToken },
        })
        .then(
          (res) => {
            setData(res.data);
          },
          async (err) => {
            if (!(err.response.status === 403)) {
              console.log(err);
            } else {
              window.location.reload();
            }
          }
        );
    }
    try {
      setData(getUserData());
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [auth]);

  return isLoading ? (
    <Loading loading={true} />
  ) : (
    <>
      <Navbar title={"Profile"}>
        {auth.user?.isAdmin ? (
          <Tooltip left={true} tooltipText="Admin Dashboard">
            <FaUserShield
              className="cursor-pointer"
              onClick={(e) => {
                navigate("/admin");
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        <Tooltip left={true} tooltipText="Invite user">
          <FaUserPlus
            className="cursor-pointer"
            onClick={(e) => {
              setShowModal(true);
            }}
          />
        </Tooltip>
        <Tooltip left={true} tooltipText="Sign out">
          <FaSignOutAlt className="cursor-pointer" onClick={handleLogout} />
        </Tooltip>
      </Navbar>
      <div className="container mx-auto lg:w-3/4 mt-8">
        <h1 className="text-2xl text-center">{auth.user?.username}</h1>
        <div className="w-full mx-auto mt-8">
          <button
            onClick={handleNewShop}
            className="cursor-pointer bg-blue-500 text-white p-2 rounded inline-block mx-auto"
          >
            Create New Shop
          </button>
          <Table auth={auth} data={data} />
        </div>
      </div>
      <InviteModal auth={auth} closeModal={closeModal} show={showModal} />
    </>
  );
};

export default Profile;
