type Props = { shops: any };

const ShopTable = ({ shops }: Props) => {
  let keys = null;
  if (shops !== undefined && shops.length !== 0) keys = Object.keys(shops[0]);

  return !shops || shops.length === 0 ? (
    <h1 className="text-center mt-8">No Shops</h1>
  ) : (
    <div>
      <table className="text-center border shadow-lg table-fixed w-full mt-2">
        <thead className="bg-slate-50 text shadow">
          <tr>
            {keys?.map((key) => {
              if (key === "id") return <></>;
              return (
                <td key={key}>
                  {key.charAt(0).toUpperCase() + key.substring(1)}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className="">
          {!shops || shops.length === 0 ? (
            <tr>
              <td colSpan={keys?.length}>No Items</td>
            </tr>
          ) : (
            shops.map((shop: any) => {
              return (
                <tr className="even:bg-slate-100" key={shop.id}>
                  <td>{shop.name}</td>
                  <td>{shop.title}</td>
                  <td>{shop.items.length}</td>
                  <td>{shop.owner.username}</td>
                  <td>{shop.admins.length}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShopTable;
