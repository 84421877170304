import React, { ReactElement, useState } from "react";

type Props = {
  itemTab: ReactElement;
  adminTab: ReactElement;
  prefrencesTab: ReactElement;
  ordersTab: ReactElement;
  statsTab: ReactElement;
};

const DashboardTabNav = ({
  itemTab,
  adminTab,
  prefrencesTab,
  ordersTab,
  statsTab,
}: Props) => {
  const storedTab = localStorage.getItem("dashboardTab");
  const [selection, setSelection] = useState(storedTab ?? "Items");

  return (
    <>
      <button
        className={"mr-2 " + (selection === "Items" ? "font-medium" : "")}
        onClick={(e) => {
          localStorage.setItem("dashboardTab", "Items");
          setSelection("Items");
        }}
      >
        Items
      </button>
      <button
        className={"mr-2 " + (selection === "Stats" ? "font-medium" : "")}
        onClick={(e) => {
          localStorage.setItem("dashboardTab", "Stats");
          setSelection("Stats");
        }}
      >
        Stats
      </button>
      <button
        className={"mr-2 " + (selection === "Orders" ? "font-medium" : "")}
        onClick={(e) => {
          localStorage.setItem("dashboardTab", "Orders");
          setSelection("Orders");
        }}
      >
        Orders
      </button>
      <button
        className={"mr-2 " + (selection === "Prefrences" ? "font-medium" : "")}
        onClick={(e) => {
          localStorage.setItem("dashboardTab", "Prefrences");
          setSelection("Prefrences");
        }}
      >
        Prefrences
      </button>
      <button
        className={"mr-2 " + (selection === "Admins" ? "font-medium" : "")}
        onClick={(e) => {
          localStorage.setItem("dashboardTab", "Admins");
          setSelection("Admins");
        }}
      >
        Admins
      </button>
      <div className="border-b" />
      {selection === "Admins" ? adminTab : <></>}
      {selection === "Items" ? itemTab : <></>}
      {selection === "Prefrences" ? prefrencesTab : <></>}
      {selection === "Orders" ? ordersTab : <></>}
      {selection === "Stats" ? statsTab : <></>}
    </>
  );
};

export default DashboardTabNav;
