import { SyntheticEvent, useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";
import { FaStore, FaUser } from "react-icons/fa";
import AlertRedirect from "./AlertRedirect";
import DashboardTabNav from "./DashboardTabNav";
import PrefrencesTab from "./PrefrencesTab";
import AdminTab from "./AdminTab";
import ItemTab from "./ItemTab";
import { Theme } from "../../types/Theme";
import OrdersTab from "./OrdersTab";
import StatsTab from "./StatsTab";

type Props = { auth: AuthState };

const ShopDashboard = ({ auth }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const [title, setTitle] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [defaultMessage, setDefaultMessage] = useState<string>("");
  const [navbarColor, setNavbarColor] = useState<string>("");
  const [navbarTextColor, setNavbarTextColor] = useState<string>("");
  const [orders, setOrders] = useState<Array<any>>([]);
  const [theme, setTheme] = useState<Theme>({
    navbarColor: "",
    navbarTextColor: "",
  });

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location as any)?.state?.from.pathname;

  useEffect(() => {
    const getShopData = async () => {
      let path = "/api/dashboard/" + params?.shop;
      axiosClient(path, {
        method: "get",
        withCredentials: true,
        headers: { Authorization: "Bearer " + auth.user?.accessToken },
      }).then(
        (res) => {
          setData(res.data);
          setTitle(res.data.title);
          setNumber(res.data.number);
          setDefaultMessage(res.data?.prefrences?.defaultMessage ?? "");
          setNavbarColor(res.data?.prefrences?.navbarColor ?? "");
          setNavbarTextColor(res.data?.prefrences?.navbarTextColor ?? "");
          setTheme({
            navbarColor: res?.data?.prefrences.navbarColor ?? "",
            navbarTextColor: res?.data?.prefrences?.navbarTextColor ?? "",
          });
          setOrders(res?.data?.orders);
          console.log(res);
        },
        (err) => {
          if (!(err.response.status === 403)) {
            console.log(err);
          } else {
            window.location.reload();
          }
        }
      );
      return () => {
        setData({});
        setIsLoading(true);
      };
    };

    try {
      getShopData();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    return () => {
      setData({});
      setTitle("");
      setNumber("");
      setDefaultMessage("");
      setNavbarColor("");
      setNavbarTextColor("");
      setIsLoading(true);
    };
  }, [auth, params?.shop]);

  if (
    !isLoading &&
    data?.admins &&
    !data.admins.find((x: any) => x?.username === auth.user?.username)
  ) {
    alert("You are not an admin of this shop");
    return <Navigate to={from ? from : "/"} replace />;
  }

  const handleNewItem = () => {
    navigate("/new/item", { state: { from: location } });
  };

  const handleDelete = (id: string) => {
    axiosClient("/api/item/" + id, {
      method: "delete",
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
    }).then(
      (res) => {
        setData((prev: any) => {
          return { ...prev, items: prev.items.filter((x: any) => x.id !== id) };
        });
      },
      (err) => {
        console.error(err);
        window.location.reload();
      }
    );
  };

  const handleDeleteShop = async () => {
    if (!window.confirm("Are you sure you want to delete this shop?")) return;

    await axiosClient("/api/shop/" + data.name, {
      method: "delete",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
    }).then(
      (res) => {
        alert("Successfully deleted shop");
        navigate("/profile");
      },
      (err) => {
        window.alert(
          err.response.data.message
            ? err.response.data.message
            : "Error deleting shop"
        );
      }
    );
  };

  const handleTransferOwnership = async (
    e: SyntheticEvent,
    username: string
  ) => {
    if (
      !window.confirm(
        "Are you sure you want to transfer ownership to " + username
      )
    ) {
      return;
    }
    axiosClient("/api/dashboard/" + data.name + "/owner", {
      method: "put",
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        username: username,
        name: data.name,
      },
    }).then((result) => {
      setData(result.data);
    });
  };

  const handleRemoveAsAdmin = async (e: SyntheticEvent, username: string) => {
    if (
      !window.confirm(
        "Are you sure you want to remove " + username + " as admin?"
      )
    ) {
      return;
    }
    axiosClient("/api/admin", {
      method: "delete",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        name: data.name,
        username: username,
        id: data.id,
      },
    }).then(
      (res) => {
        alert("Success");
        setData((prev: any) => {
          return { ...prev, admins: res.data.admins };
        });
      },
      (err) => {
        alert(
          err.response.data.message ? err.response.data.message : err.status
        );
      }
    );
  };

  const handleLeaveShop = async () => {
    if (!window.confirm("Are you sure you want leave this shop?")) {
      return;
    }
    axiosClient("/api/admin", {
      method: "delete",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        name: data.name,
        username: auth.user?.username,
        id: data.id,
      },
    }).then(
      (res) => {
        navigate("/profile");
      },
      (err) => {
        alert(
          err.response.data.message ? err.response.data.message : err.status
        );
      }
    );
  };

  const handleAddAdmin = (e: SyntheticEvent) => {
    e.preventDefault();

    const username = ((e.target as HTMLFormElement)[1] as HTMLInputElement)
      .value;

    axiosClient("/api/admin", {
      method: "post",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        name: data.name,
        username: username,
        id: data.id,
      },
    }).then(
      (res) => {
        alert("Success");
        setData((prev: any) => {
          return { ...prev, admins: res.data.admins };
        });
      },
      (err) => {
        alert(
          err.response.data.message ? err.response.data.message : err.status
        );
      }
    );
  };

  return isLoading ? (
    <Loading loading={true} />
  ) : !data ? (
    <AlertRedirect message={"No shop with that name"} to={"/profile"} />
  ) : (
    <div className="flex-col h-screen">
      <Navbar theme={theme} link={"/" + data.name} title={"Dashboard"}>
        <Link to={"/" + data.name}>
          <FaStore />
        </Link>
        <Link to={"/profile"}>
          <FaUser />
        </Link>
      </Navbar>
      <div className="container mx-auto lg:w-3/4 mt-8 flex-grow">
        <h1 className="text-2xl text-center font-medium w-fit mx-auto">
          {title}
        </h1>
        <div className="p-4">
          <DashboardTabNav
            itemTab={
              <ItemTab
                data={data}
                setData={setData}
                auth={auth}
                handleNewItem={handleNewItem}
                handleDelete={handleDelete}
                theme={theme}
              />
            }
            adminTab={
              <AdminTab
                theme={theme}
                handleAddAdmin={handleAddAdmin}
                handleTransferOwnership={handleTransferOwnership}
                handleRemoveAsAdmin={handleRemoveAsAdmin}
                data={data}
                auth={auth}
              />
            }
            prefrencesTab={
              <PrefrencesTab
                auth={auth}
                data={data}
                title={title}
                number={number}
                defaultMessage={defaultMessage}
                navbarColor={navbarColor}
                navbarTextColor={navbarTextColor}
                setTitle={setTitle}
                setNumber={setNumber}
                setDefaultMessage={setDefaultMessage}
                setNavbarColor={setNavbarColor}
                setNavbarTextColor={setNavbarTextColor}
                handleDeleteShop={handleDeleteShop}
                handleLeaveShop={handleLeaveShop}
                theme={theme}
                setTheme={setTheme}
              />
            }
            ordersTab={<OrdersTab orders={orders} setOrders={setOrders} auth={auth} />}
            statsTab={<StatsTab orders={orders} />}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopDashboard;
