import React, { SyntheticEvent } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { AuthState } from "../../types/AuthState";
import axiosClient from "../../utils/axiosClient";

type Props = { auth: AuthState };

const NewShop = ({ auth }: Props) => {
  const navigate = useNavigate();
  const from = (useLocation() as any).state?.from.pathname;

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const name = ((e.target as HTMLFormElement)[0] as HTMLInputElement)?.value;
    const title = ((e.target as HTMLFormElement)[1] as HTMLInputElement)?.value;
    const number = ((e.target as HTMLFormElement)[2] as HTMLInputElement)
      ?.value;
    const message = ((e.target as HTMLFormElement)[3] as HTMLInputElement)
      ?.value;

    axiosClient("/api/shop", {
      method: "post",
      withCredentials: true,
      headers: { Authorization: "Bearer " + auth.user?.accessToken },
      data: {
        name: name,
        title: title,
        number: number,
        username: auth.user?.username,
        defaultMessage: message,
      },
    }).then(
      () => {
        navigate(from ? from : "/");
      },
      (err) => {
        if (!(err.response.status === 403)) {
          alert(err.response.data.message);
        } else {
          window.location.reload();
        }
      }
    );
  };

  return (
    <div>
      <Navbar
        titleElement={
          <FaChevronLeft
            className="cursor-pointer"
            onClick={() => navigate(from ? from : "/profile")}
          />
        }
      ></Navbar>
      <div className="container mx-auto mt-8">
        <h1 className="font-normal text-center mb-4">New Shop</h1>
        <div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-2 w-fit mx-auto"
          >
            <input
              className="p-2 rounded border md:w-96 mx-auto"
              type="text"
              name="name"
              id="name"
              autoComplete="off"
              placeholder="Url name"
              required
            />
            <input
              className="p-2 rounded border md:w-96 mx-auto"
              type="text"
              name="title"
              id="title"
              placeholder="Shop title"
              required
            />
            <input
              className="p-2 rounded border md:w-96 mx-auto"
              type="text"
              name="number"
              id="number"
              placeholder="Swish number"
              required
            />
            <input
              className="p-2 rounded border md:w-96 mx-auto"
              type="text"
              name="message"
              id="message"
              placeholder="Default message"
            />
            <input
              className="p-2 bg-blue-500 text-white md:w-96 mx-auto cursor-pointer"
              type="submit"
              value="Create Shop"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewShop;
