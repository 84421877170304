import React from "react";
import { AuthState } from "../../types/AuthState";
import { Theme } from "../../types/Theme";
import AdminTable from "./AdminTable";

type Props = {
  handleAddAdmin: Function;
  handleTransferOwnership: Function;
  handleRemoveAsAdmin: Function;
  data: any;
  auth: AuthState;
  theme: Theme;
};

const AdminTab = ({
  handleAddAdmin,
  handleTransferOwnership,
  handleRemoveAsAdmin,
  data,
  auth,
  theme,
}: Props) => {
  return (
    <div className=" lg:mx-auto w-full pt-4 pb-8">
      <form onSubmit={(e) => handleAddAdmin(e)}>
        <input
          className="p-1 rounded"
          style={{
            backgroundColor:
              theme && theme.navbarColor !== ""
                ? "#" + theme?.navbarColor
                : "#3b82f6",
            color:
              theme && theme.navbarTextColor !== ""
                ? "#" + theme?.navbarTextColor
                : "#fff",
          }}
          type="submit"
          value="Add Admin"
        />
        <input
          className="ml-2 p-1 rounded border"
          type="text"
          name="admin"
          id="admin"
        />
      </form>
      <AdminTable
        auth={auth}
        owner={data.owner}
        data={data.admins}
        handleTransferOwnership={handleTransferOwnership}
        handleRemoveAsAdmin={handleRemoveAsAdmin}
      />
    </div>
  );
};

export default AdminTab;
