import { FaCrown, FaExchangeAlt, FaUserMinus } from "react-icons/fa";
import Tooltip from "../../components/Tooltip";
import { AuthState } from "../../types/AuthState";

type Props = {
  auth: AuthState;
  data: any;
  owner: any;
  handleTransferOwnership: Function;
  handleRemoveAsAdmin: Function;
};

const AdminTable = ({
  auth,
  data,
  owner,
  handleTransferOwnership,
  handleRemoveAsAdmin,
}: Props) => {
  return (
    <table className="table-fixed text-center border shadow-lg w-full mt-2">
      <thead className="bg-slate-50  shadow">
        <tr>
          <th className="font-light">Name</th>
          <th className="font-light">Role</th>
        </tr>
      </thead>
      <tbody className="">
        <tr>
          <td className="p-2">{owner?.username}</td>
          <td>
            <span>Owner</span>
            <FaCrown className="ml-4 text-orange-400 inline-block absolute translate-y-1" />
          </td>
        </tr>
        {!data || data.length === 0 ? (
          <tr>
            <td colSpan={3}>No Admins</td>
          </tr>
        ) : (
          data
            .filter((x: any) => x.username !== owner.username)
            .map((user: any) => {
              return (
                <tr key={user.id} className="even:bg-slate-100">
                  <td className="p-2">
                    <span className="relative">{user.username}</span>
                  </td>
                  <td>
                    <span>Admin</span>
                    {auth?.user?.username === owner.username ? (
                      <>
                        <Tooltip
                          tooltipText="Transfer Ownership"
                          className="inline-block absolute ml-4"
                          childClassName="translate-y-1"
                        >
                          <FaExchangeAlt
                            className="cursor-pointer"
                            onClick={(e) =>
                              handleTransferOwnership(e, user.username)
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          tooltipText="Remove as admin"
                          className="inline-block absolute ml-10"
                          childClassName="translate-y-1"
                        >
                          <FaUserMinus
                            className="cursor-pointer"
                            onClick={(e) =>
                              handleRemoveAsAdmin(e, user.username)
                            }
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
  );
};

export default AdminTable;
