import { AuthState } from "../../types/AuthState";

import ItemTableRow from "./ItemTableRow";

type Props = { data: any; auth: AuthState; handleDelete: Function, setData: Function};

const ItemTable = ({ data, auth, handleDelete, setData}: Props) => {

  const updateItem = (item: any) => {
    let updatedItems = data.items.map((x: any) => {
      if(x.id === item.id){
        x.name = item.name;
        x.price = item.price;
      }
      return x;
    }) 

    setData({...data, items: updatedItems})
  }

  return (
    <table className="text-center border shadow-lg table-fixed w-full mt-2">
      <thead className="bg-slate-50 text shadow">
        <tr>
          <th className="font-light">Name</th>
          <th className="font-light">Price</th>
          <th className="font-light">Edit Item</th>
          <th className="font-light">Delete Item</th>
        </tr>
      </thead>
      <tbody className="">
        {!data.items || data.items.length === 0 ? (
          <tr>
            <td className="p-2" colSpan={4}>No Items</td>
          </tr>
        ) : (
          data.items.map((item: any) => {
            return (
              <ItemTableRow
                auth={auth}
                key={item.id}
                item={item}
                updateItem={updateItem}
                handleDelete={handleDelete}
              />
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default ItemTable;
