const generateSwishURI = (
  phone: string,
  amount: number,
  message: string,
  shopName: string,
  ref: string
) => {
  const baseURL = "swish://payment?data=";
  //const internationalPhoneNumber: string = "+46" + phone.substr(1);
  const integerAmount: number = amount;

  const data = {
    version: 1,
    payee: {
      value: phone,
    },
    amount: {
      value: integerAmount,
    },
    message: {},
  };

  if (message.length > 0) {
    data.message = {
      value: message,
      editable: false,
    };
  }

  const json = JSON.stringify(data);
  return (
    baseURL +
    encodeURIComponent(json) +
    `&callbackurl=https://swisha.shop/api/callback/${shopName}/${ref}/&callbackresultparameter=res`
  );
};

export default generateSwishURI;
