import { useState } from "react";

type Props = {
  item: any;
  initial?: number;
  theme?: { navbarColor: string; navbarTextColor: string };
  setOrder: Function;
  order: Order;
};

type Order = { total: number; rows: Array<{ item: any; amount: number }> };

const Item = ({ item, theme, initial, setOrder }: Props) => {
  const [amount, setAmount] = useState(initial ?? 0);

  const handleClickAdd = () => {
    setAmount((prev) => prev + 1);

    setOrder(
      (prevOrder: {
        total: number;
        rows: Array<{ item: any; amount: number }>;
      }) => ({
        total: prevOrder.total + item.price,
        rows: [
          ...prevOrder.rows.filter((x) => x.item.id !== item.id),
          { item: item, amount: amount + 1 },
        ],
      })
    );
  };

  const handleClickSub = () => {
    if (amount > 0) {
      setOrder((prevOrder: Order) => ({
        total: prevOrder.total - item.price,
        rows: [
          ...prevOrder.rows.filter((x) => x.item.id !== item.id),
          { item: item, amount: amount - 1 },
        ],
      }));
      setAmount(amount - 1);
    }
    if (amount - 1 === 0) {
      setOrder((prevOrder: Order) => ({
        total: prevOrder.total,
        rows: [...prevOrder.rows.filter((x) => x.item.id !== item.id)],
      }));
    }
  };

  return (
    <div
      className="m-2 shadow-lg border rounded-3xl cursor-pointer transition relative"
      key={item.id.toString()}
    >
      <div
        className={
          "absolute shadow-md aspect-square rounded-full w-8 h-8 text-center" +
          (amount > 0 ? "" : " hidden")
        }
        style={{
          backgroundColor:
            theme && theme.navbarColor !== ""
              ? "#" + theme?.navbarColor
              : "#3b82f6",
          color:
            theme && theme.navbarTextColor !== ""
              ? "#" + theme?.navbarTextColor
              : "#fff",
        }}
      >
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">{amount}</span>
      </div>
      <div className="h-full align-stretch rounded-2xl text-center flex justify-between">
        <div
          className="p-8 text-lg flex flex-col justify-center rounded-bl-2xl rounded-tl-2xl  font-medium"
          onClick={() => handleClickSub()}
        >
          -
        </div>
        <div className="flex-col flex justify-center flex-grow">
          <span
            className="font-medium flex flex-col justify-evenly h-full"
            onClick={() => handleClickAdd()}
          >
            <span>{item.name}</span>
          </span>
        </div>
        <div
          className="p-8 text-lg flex flex-col justify-center rounded-br-2xl rounded-tr-2xl  font-medium"
          onClick={() => handleClickAdd()}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default Item;
