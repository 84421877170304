import React from "react";
import { AuthState } from "../../types/AuthState";
import { Theme } from "../../types/Theme";
import DataSetting from "./DataSetting";
import PrefrenceSetting from "./PrefrenceSetting";

type Props = {
  auth: AuthState;
  data: any;
  title: string;
  number: string;
  defaultMessage: string;
  navbarColor: string;
  navbarTextColor: string;
  setTitle: Function;
  setNumber: Function;
  setDefaultMessage: Function;
  setNavbarColor: Function;
  setNavbarTextColor: Function;
  handleDeleteShop: Function;
  handleLeaveShop: Function;
  theme: Theme;
  setTheme: Function;
};

const PrefrencesTab = ({
  auth,
  data,
  title,
  number,
  defaultMessage,
  navbarColor,
  navbarTextColor,
  setTitle,
  setNumber,
  setDefaultMessage,
  setNavbarColor,
  setNavbarTextColor,
  handleDeleteShop,
  handleLeaveShop,
  theme,
  setTheme,
}: Props) => {
  return (
    <>
      <DataSetting
        auth={auth}
        data={data}
        settingTitle={"Title"}
        settingName={"title"}
        settingValue={title}
        setSettingValue={setTitle}
        theme={theme}
      />
      <DataSetting
        auth={auth}
        data={data}
        settingTitle={"Number"}
        settingName={"number"}
        settingValue={number}
        setSettingValue={setNumber}
        theme={theme}
      />
      <PrefrenceSetting
        auth={auth}
        data={data}
        settingTitle={"Default Message"}
        settingName={"defaultMessage"}
        settingValue={defaultMessage}
        setSettingValue={setDefaultMessage}
        maxLength={50}
        theme={theme}
      />
      <PrefrenceSetting
        auth={auth}
        data={data}
        settingTitle={"Primary Color"}
        settingName={"navbarColor"}
        settingValue={navbarColor}
        setSettingValue={setNavbarColor}
        theme={theme}
        hex
        setTheme={setTheme}
        tooltip="Color of navbar and buttons"
      />
      <PrefrenceSetting
        auth={auth}
        data={data}
        settingTitle={"Secondary Color"}
        settingName={"navbarTextColor"}
        settingValue={navbarTextColor}
        setSettingValue={setNavbarTextColor}
        theme={theme}
        hex
        setTheme={setTheme}
        tooltip="Text color etc."
      />
      {auth.user?.username === data?.owner?.username ? (
        <div className="mx-auto mt-10 w-fit">
          <button
            onClick={(e) => handleDeleteShop()}
            className="bg-red-500 text-white p-2 rounded"
          >
            Delete shop
          </button>
        </div>
      ) : (
        <div className="mx-auto mt-20 w-fit">
          <button
            onClick={() => {
              handleLeaveShop();
            }}
            className="bg-red-500 text-white p-2 rounded"
          >
            Leave shop
          </button>
        </div>
      )}
    </>
  );
};

export default PrefrencesTab;
