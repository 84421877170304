import React, { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { Theme } from "../types/Theme";

type Props = {
  children?: ReactNode;
  titleElement?: ReactNode;
  title?: string;
  link?: string;
  fixed?: boolean;
  theme?: Theme;
};

const Navbar = ({
  titleElement,
  children,
  title,
  link,
  fixed,
  theme,
}: Props) => {
  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return (
    <div
      className={
        "p-4 flex justify-between shadow-lg " +
        (!titleElement ? "items-center" : "")
      }
      style={{
        backgroundColor: "#" + theme?.navbarColor,
        color: "#" + theme?.navbarTextColor,
        position: fixed ? "absolute" : "initial",
        width: fixed ? "100vw" : "initial",
      }}
    >
      {titleElement ? (
        titleElement
      ) : (
        <Link to={link ? link : "/"}>{title ? title : "SwishLink"}</Link>
      )}
      <div className="flex items-center justify-around gap-4">{children}</div>
    </div>
  );
};

export default Navbar;
